import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/theme/layout'
import HeroImage from '../components/contentful/hero-image'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options"
import { buildBreadcrumbsItem } from "../helpers.js"
import Header from "../components/theme/header";
import Footer from "../components/theme/footer";
import StickyShare from "../components/sticky-share/sticky-share";


class PageTemplate extends React.Component {
  constructor(props) {
    super(props);    
    this.page = get(this.props, 'data.contentfulPage')
    this.breadcrumbs = [buildBreadcrumbsItem(this.page.title, this.props.location.href)]
    this.ogImage = get(this.page, 'shareImage.fixed') || get(this.page, 'heroImage.fixed')

    this.seo = {
      title: (this.page.seoTitle || this.page.title).replace(/Honest Paws$/, 'Honest Paws®'),
      meta: {
        title: (this.page.seoTitle || this.page.title).replace(/Honest Paws$/, 'Honest Paws®'),
        description: this.page.metaDescription,
        image: this.ogImage,
        ogMeta: {
          'og:type': 'website'
        }
      },
      children: [],
      disallowIndexing: this.page.disallowIndexing || false
    }
  }
  render() {
    const options = {
      ...defaultRTRenderOptions
    }

    let headerOptions = {
      location: this.props.location
    }

    let footerProps = {}

    // Compliant header and footer
    if (this.page.compliantHeaderFooter){
      headerOptions.menu = null
      headerOptions.hidelinkonlogo = true

      footerProps.hidelinkonlogo= true
      footerProps.hidelinks= true
      footerProps.menu= null
    }

    const footer = (<Footer { ...footerProps } />)
    const header = (<Header { ...headerOptions } />)
    
    if (this.page.canonicalUrl) {
      this.seo.children.push(<link rel="canonical" href={ this.page.canonicalUrl } key='canonical-url' />)
    }
    if (this.page.schemaOrgJson) {
      this.seo.children.push(<script type="application/ld+json" key='structured-data'>{ this.page.schemaOrgJson.internal.content }</script>)
    }
    
    return (
      <Layout location={this.props.location} containerClassName={`page contentful-page ${this.page.slug} page-${this.page.contentful_id}`}
              hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo } />
        { this.page.showShareComponent && <StickyShare shareDialogTitle={this.seo.meta.title} shareDialogText={this.seo.meta.description}/> }

        {this.page.heroImage ? (
          <HeroImage image={ this.page.heroImage } className="page-hero"/>
        ) : (
        <div className="page-header-top-placeholder"></div>
        )}
        <section className="page-title-and-summary text-center px-4 page-header">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">{ this.page.title }</h1>              
              { this.page.summary ? 
                <div className="page-summary mt-3">
                  { documentToReactComponents(this.page.summary.json, options) }
                </div> : '' }              
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container page-content-container">
            {documentToReactComponents(this.page.body.json, options)}
          </div>
        </section>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      ...contentfulPage
    }
  }
`
